@import "~bulma/sass/utilities/initial-variables";
@import '~ngx-toastr/toastr';

$link : #2e7d86;
$primary: #08821e;
$danger: #86312e;
$frog-green1: #007237;
$frog-green2: #2E8637;
$table-head-background-color: #f1f1f1; 
:root 
{
    --frog-green1: #3b6d40;
    --frog-green2: #2E8637;
    --frog-danger: #86312e;
    --border: #dbdbdb;
}

@import "~bulma/bulma";
@import '~font-awesome/scss/font-awesome';

.page-title{
    display: block;
    font-size: 1.5em;
    font-weight: bold;
    padding: 0.25em;
    padding-left: 1em;
}
.mainbody{
    position: fixed; 
    top: 52px;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
}
.left-nav{
    background-color: #eee;
    border-right: solid 1px #ddd;
    padding-left: 2em;
    position: absolute;
    left: 0;
    width: 15vw;
    top: 0;
    bottom: 0;
    overflow-y: auto;
}
.maincontent{
    position: absolute;
    left: 16vw;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
}
@media screen and (max-width: 1024px), print{
    .left-nav{
        display: none;
    }
    .maincontent{
        left: 0;
    }
}

.input{
    max-width: 40em;
    &.is-short{
        max-width: 20em
    }
    &.is-shorter{
        max-width: 7em;
    }
    &.is-shortest{
        max-width: 3em;
    }
}

body{
    opacity: 1;
}
.dialog{
//    opacity: 0;
//    transition: opacity 300ms ease-in-out;
//     will-change: opacity;
//     &.in{
//         opacity: 1;
//     }
//     &.out{
//         opacity: 0;
//     }
}
.modal-content, .modal-card{
    width: auto;
    
}
.modal-card-foot{
    justify-content: right;
}
.loading-container{
    position: relative;
}
.toast-success{
    background-color: var(--frog-green2);
}
.toast-error{
    background-color: var(--frog-danger);
}

.ng-autocomplete {
    .autocomplete-container{
        border: none;
        box-shadow: none;
        .input-container{
            input{
                @extend .input;   
            }
        }
    }
}

/*   */
@media screen and (min-width: 769px), print{
    .field-label {
        flex-grow: 0;
        min-width:12em;
    }   
}

/*Z INDEXES IN ONE PLACE TO EASILY SEE*/
.modal{
    z-index: 50
}



/* DEVICE STATUS COLORS */
.device-status-Ok{
    background: linear-gradient(90deg, #f1f1f1 40%, #ddd 80%, #C7DBC9);
}
.device-status-Degraded{
    background: linear-gradient(90deg, #f1f1f1 40%, #ddd 80%, #D2D3A7);
}
.device-status-Fault{
    background: linear-gradient(90deg, #f1f1f1 40%, #ddd 80%, #D9CCB3);
}
.device-status-Offline{
    background: linear-gradient(90deg, #f1f1f1 40%, #ddd 80%, #C8AE9D);
}

.panel{
    .panel-block{
        .label{
            display: inline-block;
            width: 15em;
            padding-left: 1em;
            overflow: hidden;
            font-weight: bold;
            margin: 0;
        }
    }
}

.flex-grow {
    flex-grow: 1;
}

.input-table {
 width: 100%;
}

.input-table tbody tr td .select {
    width: 100%;
}

.input-table tbody tr td {
    width: 32%;
}

.flex-leading-sm {
    width:4em;
    margin-right:0 !important;
}

.flex-leading-sm input {
    width:4em;
}

.field.has-addons .control.is-expanded {
    flex-grow: 0;
    flex-shrink: 1;
}